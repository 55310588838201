import { Controller } from "@hotwired/stimulus"

const CLOSED_ANNOUNCEMENTS_LOCAL_STORAGE_KEY = "hiddenAnnouncements"
const SEPARATOR = ","

export default class extends Controller {
  static values = {
    announcementKey: String
  }

  static classes = [
    "hidden"
  ]

  initialize() {
    const hasBeenClosed = this.#checkHasBeenClosed()
    const shouldShow = !hasBeenClosed

    if (shouldShow) {
      this.#show()
    }

    this.#migrateAnnouncementsStorage()
  }

  dismiss() {
    this.#markHasBeenClosed()
    this.#hide()
  }

  #hide() {
    this.element.classList.add(this.hiddenClass)
  }

  #show() {
    this.element.classList.remove(this.hiddenClass)
  }

  #getClosedAnnouncements() {
    const baseString = localStorage.getItem(CLOSED_ANNOUNCEMENTS_LOCAL_STORAGE_KEY) || ""
    return baseString.split(SEPARATOR)
  }

  #setClosedAnnouncements(newClosedAnnouncements) {
    const closedAnnouncementsString = newClosedAnnouncements.join(SEPARATOR)
    localStorage.setItem(CLOSED_ANNOUNCEMENTS_LOCAL_STORAGE_KEY, closedAnnouncementsString)
  }

  #markHasBeenClosed() {
    const newClosedAnnouncements = this.#getClosedAnnouncements()
    newClosedAnnouncements.push(this.announcementKeyValue)

    this.#setClosedAnnouncements(newClosedAnnouncements)
  }

  #checkHasBeenClosed() {
    const closedAnnouncements = this.#getClosedAnnouncements()
    return closedAnnouncements.includes(this.announcementKeyValue)
  }

  #migrateAnnouncementsStorage() {
    const [thisAnnouncementID] = this.announcementKeyValue.split(":")

    const existingHiddenAnnouncements = this.#getClosedAnnouncements()

    const revisedHiddenAnnouncements = existingHiddenAnnouncements.filter((hiddenAnnouncementKey) => {
      const [hiddenAnnouncementID] = hiddenAnnouncementKey.split(":")
      return !(hiddenAnnouncementID === thisAnnouncementID && hiddenAnnouncementKey !== this.announcementKeyValue)
    })

    this.#setClosedAnnouncements(revisedHiddenAnnouncements)
  }
}
